<template>
    <div>
        <div
        	v-for="(item, index) in unitPriceList"
            :key="index"
            class="row mb-2"
        >
			<div
				v-for="(subItem,
				subIndex) in selectedLanePricingMethods.allowedValues"
				:key="subIndex"
				class="col-md-5"
			>
                <div v-if="subItem.key != 'price'">
                  <base-input
                    type="number"
                    :label="subItem.name"
                    step="any"
                    :name="subItem.name"
                    class="mb-0"
                    rules="required"
                    v-model="item[subItem.key]"
                   
                    @input="checkDuplicateUnit"
                    @blur="checkDuplicateUnit"
                    v-on:keypress="positiveNumbersOnly"
                  >
                  </base-input>
                </div>
                <div v-if="subItem.key == 'price'">
                  <div class="form-group has-label mb-0">
                    <label>{{ subItem.name }}</label>
                    <div>
                      <ValidationProvider
                        rules="required"
                        name="Price"
                        v-slot="{ errors }"
                      >
                      <span class="data-type-input currency-symbol-custom" :data-symbol="currencySymbol" :class="{ 'currency-symbol-dollarSign': currencySymbol === '$' }"> 
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            placeholder="00.00"
                            v-model="item.price"
                            v-on:keypress="positiveNumbersOnly"
                            @input="validateInput(index)"
                            @blur="addDecimal(index)"
                          />
                        </span>
                        <span class="validate-error" style="display: block;">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
            </div>

			<div class="col-md-2">
				<i
					v-if="unitPriceList.length > 1"
					class="fa fa-trash unit-price-delete"
					@click="removeUnitPrice(index)"
				>
				</i>
			</div>
        </div>
    </div>
</template>

<script>
export default {
    bodyClass: "landing",
    props: {
        unitPriceList: {
        	type: Array
    	},
		selectedLanePricingMethods: {
			type: Object
		},
		currencySymbol: {
			type: String
		}
    },
    data: function() {
        return {
            unitPriceErrorMsg: "",
            isDuplicate: false,
        }
    },
    methods: {
		/**
		 * Check if there are duplicate units in the unit price list
		 * @returns {boolean} - True if there are duplicate units, false otherwise
		 */
		getDuplicateUnit() {
			const uniqueArray = this.unitPriceList.filter((item, index) => {
				return (
				this.unitPriceList.findIndex(i => i.unit === item.unit) === index
				);
			});

			return this.unitPriceList.length !== uniqueArray.length;
		},
		/**
		 * Check for duplicate units and display an error message if necessary
		 */
		checkDuplicateUnit() {
			this.unitPriceErrorMsg = "";
			if (this.getDuplicateUnit()) {
				this.unitPriceErrorMsg = "Please enter a unique unit value";
			}
		},
		/**
		 * Allow only positive numbers in an input field
		 * @param {Event} evt - The event object
		 * @returns {boolean} - True to allow the input, false to prevent it
		 */
		positiveNumbersOnly(evt) {
			this.isDuplicate = true;
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		/**
		 * Validate the input for a unit price item
		 * @param {number} i - The index of the unit price item
		 */
		validateInput(i) {
			let fixedValue = this.unitPriceList[i].price;
			if (fixedValue.includes(".")) {
				const parts = fixedValue.split(".");
				if (parts[1] && parts[1].length > 2) {
					this.unitPriceList[i].price = `${parts[0]}.${parts[1].substr(0, 2)}`;
				}
			}
		},
		/**
		 * Add decimal places to the price if not present
		 * @param {number} i - The index of the unit price item
		 */
		addDecimal(i) {
			let fixedValue = this.unitPriceList[i].price;
			if (!fixedValue.includes(".")) {
				this.unitPriceList[i].price = fixedValue + ".00";
			}
		},
		/**
		 * Remove a unit price from the unit price list
		 * @param {number} i - The index of the unit price to remove
		 */
		removeUnitPrice(i) {
			this.unitPriceList.splice(i, 1);
			this.isDuplicate = true;
		},
    }
}
</script>