<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <div class="form-group has-label">
          <label> {{ label }} </label>
          <vue-google-autocomplete
            classname="form-control mb-1"
            placeholder="City - Postal code"
            :id="label === 'Origin' ? 'origin-map' : 'destination-map'"
            v-model="localOriginDestination"
            types="(regions)"
            :country="country"
            v-on:change="event => handleGetOriginData(event)"
          >
          </vue-google-autocomplete>
          <span v-if="originInvalid" class="validate-error"
            >The Origin field is required</span
          >
          <span v-if="destinationInvalid" class="validate-error"
            >The Destination field is required</span
          >
        </div>
      </div>
    </div>
      
    <div class="mb-3" v-if="!isAddCityToGroup">
      <a class="btn btn-link a-btn" @click="handleAddCityToGroup">
        {{ label == 'Destination' ? 'Add city to group +' : 'Add Sub-city +' }}
      </a>
    </div>

    <!-- Add city for both origin and destination -->
    <div v-if="isAddCityToGroup" class="row">
      <div class="col-lg-2"></div>
      <div class="col-md-12 col-lg-6">
        <div
          v-for="(item, index) in additionalCityToGroupList"
          :key="index"
          class="row mb-2"
        >
          <div class="col-10 col-md-10 autocomplete-pr has-label">
            <vue-google-autocomplete
              :ref="item.city"
              classname="form-control mb-1"
              placeholder="City - Postal code"
              :id="label === 'Origin' ? 'sub_city_' + index : 'group_city_' + index"
              v-model="item.city"
              types="(regions)"
              :country="country"
              @inputChange="handleSetGroupCityIndex(index)"
              v-on:change="handleGetGroupCityData"
            >
            </vue-google-autocomplete>
          </div>
          <div class="col-2 col-md-2">
            <i
              class="fa fa-trash delete-icon"
              @click="handleRemoveGroupCity(index)"
            >
            </i>
          </div>
        </div>
        <div class="mb-3 mt-2">
          <a class="btn btn-link a-btn" @click="handleAddAdditionalGroupCity">
            Add additional city to group +
          </a>
        </div>
      </div>
      <div class="col-md-5"></div>
    </div>

    <div class="create-section-text mb-2 mt-3">
        Select a Pricing Method for this lane section
    </div>
    <div class="field-dropdown-main mb-4 price-method-dropdwon">
        <choices-single
          :id="label == 'Origin' ? 'editLanePriceMethod' : 'editLaneDestinationPriceMethod' "
          :options="priceMethodList"
          v-model="localSelectedPriceMethod"
          @input="event => handleSelectPriceMethod(event)"
        >
          <option value="0">Select</option>
        </choices-single>
        <span v-if="priceMethodInvalid" class="validate-error">
          The Price method is required
        </span>
    </div>

    <!-- Currency type dropdown starts-->
    <currency-selection 
      @currency-default-changed="handleCurrencyChangeCrossBorder"
      :currency.sync="localCurrency"
    />

    <div
      class="fixed-input mb-4"
      v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'fixed'"
    >
      <span class="data-type-input left">
        <input
          type="number"
          step="any"
          class="form-control"
          placeholder="00.00"
          v-model="localDollarValue"
          @input="event => handleChangeValue(event)"
          @blur="handleAddDollarDecimal"
        />
      </span>
      <span v-if="dollarValueInvalid" class="validate-error">The Price field is required</span>
    </div>

    <div
      v-if="selectedLanePricingMethods && selectedLanePricingMethods.key != 'fixed'"
    >
      <lane-pricing-not-fixed 
        :unitPriceList="unitPriceList"
        :selectedLanePricingMethods="selectedLanePricingMethods"
        :currencySymbol="currencySymbol" 
      />
      <div
          class="mt-3"
          v-if="selectedLanePricingMethods.allowMultipleValues"
        >
          <base-button @click="handleAddUnitPrice">
            <img src="../../assets/img/plus-active.svg" />
            Add 
          </base-button>
      </div>
    </div>

    <!-- For base rate -->      
    <div v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'class'">
      <div class="row">
         <div  class="col-12 col-lg-4">
            <div class="create-section-text mb-2">
              Choose a Base Rate
            </div>
    
            <div class=" mb-4 price-method-dropdwon">
              <choices-single 
                :options="baseUnitsListing"
                v-model="localSelectedBaseUnits"
                @input="event => handleSelectBaseUnits(event)"
              >
                <option value="0">Select Base rate</option>
              </choices-single> 
            </div>
          </div>

          <div class="col-12 col-lg-4 mt-lg-4">
            <button type="button" class="btn btn-default mb-4 mb-lg-0 mt-lg-2">
              Authenticate
            </button>
          </div>
      </div>
      <div class="row">
        <div class="col-10">
          <lane-pricing-class 
            :selectedBaseUnit="localSelectedBaseUnits"
            :selectedClassUnits="localSelectedClassUnits"
            :isViewClassBreaks="localIsViewClassBreaks"
            @update:isViewClassBreaks="localIsViewClassBreaks = $event"
            @select-class-units="handleSelectClassUnits"
            :classRateInvalid="classRateInvalid"
            :currencySymbol="currencySymbol"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ChoicesSingle from "@/components/SingleSelect";
import CurrencySelection from "./CurrencySelection.vue";
import LanePricingNotFixed from "./LanePricingNotFixed.vue";
import LanePricingClass from "./LanePricingClass.vue";
import { getCurrencyType } from "@/helpers/utility";
export default {
  bodyClass: "landing",
  components: {
    ChoicesSingle,
    CurrencySelection,
    VueGoogleAutocomplete,
    LanePricingNotFixed,
    LanePricingClass
  },
  props: {
    label: {
      type: String
    },
    priceMethodList: {
        type: Array
    },
    selectedPriceMethod: {
        type: String
    },
    selectedLanePricingMethods: {
        type: Object
    },
    unitPriceList: {
        type: Array
    },
    selectedBaseUnit: {
        type: String
    },
    selectedClassUnits: {
        type: String
    },
    isViewClassBreaks: {
        type: Boolean
    },
    classRateInvalid: {
        type: Boolean
    },
    baseUnitsListing: {
        type: Array
    },
    isAddCityToGroup: {
        type: Boolean
    },
    isAddSubCity: {
        type: Boolean
    },
    additionalCityToGroupList: {
        type: Array
    },
    additionalSubCityList: {
        type: Array
    },
    dollarValue: {
        type: String
    },
    dollarValueInvalid: {
        type: Boolean
    },
    originDestination: {
        type: String
    },
    priceMethodInvalid: {
      type: Boolean
    },
    originInvalid: {
      type: Boolean
    },
    destinationInvalid: {
      type: Boolean
    },
    currency: {
      type: String
    },
  },
  data: function() {
    return {
        country: ["us", "ca"],
        localSelectedPriceMethod: this.selectedPriceMethod,
        localSelectedBaseUnits: this.selectedBaseUnit,
        localSelectedClassUnits: this.selectedClassUnits,
        localIsViewClassBreaks: this.isViewClassBreaks,
        localDollarValue: this.dollarValue,
        localOriginDestination: this.originDestination,
        currencyDefault: "USD",
        localCurrency: this.currency // Create local copy of the currency prop
    }
  },
  watch: {
    originDestination(newVal) {
      this.localOriginDestination = newVal;
    },
    selectedPriceMethod(newValue) {
      this.localSelectedPriceMethod = newValue; // Sync with prop when it changes
    },
    selectedBaseUnit(newVal) {
      this.localSelectedBaseUnits = newVal;
    },
    selectedClassUnits(newVal) {

      this.localSelectedClassUnits = newVal;
    },
    isViewClassBreaks(newVal) {
      this.localIsViewClassBreaks = newVal;
    },
    dollarValue(newVal) {
      this.localDollarValue = newVal;
    },
    // Watch for changes in the prop and update localCurrency accordingly
    currency(newValue) {
      this.localCurrency = newValue;
    },
    localCurrency(newValue) {
      this.$emit('update:currency', newValue); 
    }
  },
  computed: {
    currencySymbol() {
      const currencyType= getCurrencyType(this.currency)
      return currencyType;
    }
  },
  methods : {
    /**
     * Emits the event based on whether the label is 'Origin' or 'Destination'
     * @param {Event} e - The event object containing the relevant data
    */
    handleGetOriginData(e) {
      if(this.label === 'Origin') {
        this.$emit('get-origin-data', e)
      } else {
        this.$emit('get-destination-data', e)
      }
    },
    /**
     * Handle the selection of a price method
     * @param {Event} e - The event object
     */
    handleSelectPriceMethod(e) {
      if(this.label === 'Origin') {
        this.$emit('select-price-method', e);
        this.$emit('update:priceMethodInvalid', false); 
      } else if(this.label === 'Destination') {
        this.$emit('select-destination-price-method', e)
        this.$emit('update:priceMethodInvalid', false); 
      }
    },
    /**
     * Updates the currency default based on the new value
     * @param {String} newValue - The new currency value
    */
    handleCurrencyChangeCrossBorder(newValue) {
      this.currencyDefault = newValue;
    },
    /**
     * Emits an event to the parent to add a unit price
    */
    handleAddUnitPrice() {
      this.$emit('add-unit-price');
    },
    /**
    * Emits the selected class units to the parent
    * @param {Event} e - The event object containing the selected value
    */
    handleSelectClassUnits(e) {
      this.$emit('select-class-units', e);
    },
    /**
      * Emits the selected base units to the parent and hides the class breaks view
    */
    handleSelectBaseUnits() {
      this.$emit('select-base-units',this.localSelectedBaseUnits);
      this.localIsViewClassBreaks = false;
    },
    /**
     * Emits an event to the parent to add a city to the group
    */
    handleAddCityToGroup() {
      this.$emit('add-group-city');
    },
    /**
     * Emits an event to the parent to add another city to the group
    */
    handleAddAdditionalGroupCity() {
      this.$emit('add-additional-group-city');
    },
    /**
     * Emits an event to the parent with the selected group city data
     * @param {Event} e - The event object containing the city data
    */
    handleGetGroupCityData(e) {
      this.$emit('get-group-city-data',e);
    },
    /**
     * Emits an event to the parent to remove a city by index
     * @param {Number} index - The index of the city to remove
    */
    handleRemoveGroupCity(index) {
      this.$emit('remove-group-city',index)
    },
    /**
     * Emits the updated dollar value to the parent
    */
    handleChangeValue() {
      this.$emit('change-value', this.localDollarValue);
      this.$emit('update:dollarValueInvalid', false); 
    },
    /**
     * Emits an event to the parent to add the decimal points
    */
    handleAddDollarDecimal() {
      this.$emit('add-dollar-decimal')
    },
    /**
     * Emits the selected index to the parent
     * @param {Number} index - The index of the city group
    */
    handleSetGroupCityIndex(index) {
      this.$emit('set-group-city-index', index)
    }
  }
}
</script>