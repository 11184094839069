<template>
  <div class="tariff-app-container-height">
    <app-header :activeMenuName="activeMenuName" />
    <div class="pr-4 pl-4 section-b">
      <div>
        <!-- <div>
          <router-link to="/">
            <span class="back-text-btn">Back</span>
          </router-link>
        </div> -->
        <div class="section-title-box align-items-center d-flex">
          All Section
        </div>
        <div class="row align-items-center view-all-section">
          <div class="col-md-3 input-v1">
            <img class="t-search-icon" src="../assets/img/search.png" />
            <vue-input
              alternative
              placeholder="Search Section"
              type="text"
              class="search-box"
              v-model="searchSection"
              @input="event => searchAllSection(event)"
            ></vue-input>
          </div>
          <div class="col-md-8 col-lg-3">
            <div class="">
              <div class="select-section-title">Select a Section</div>
              <choices-single
                id="sectionField"
                :options="sectionFieldList"
                :value="selectedField"
                v-model="selectedField"
                @input="event => selectField(event)"
              >
                <option value="0">Select Section</option>
              </choices-single>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 selected-section-btn">
            <router-link
              :to="{
                name: 'create-custom-fields',
                params: { preselected: selectedField }
              }"
            >
              <base-button type="primary" class="btn-theme-24876E">
                Add New {{ selectedField }}
              </base-button>
            </router-link>
            <base-button
              v-if="userIsBroker && this.user.email.indexOf('@usepallet.com') !== 0"
              type="secondary"
              class="btn-theme-FCBB5C gray-btn sync-pallet-btn"
              :disabled="isSyncing"
              v-on:click="syncWithPallet"
            >
              <img
                :class="{ 'sync-animating': isSyncing }"
                src="../assets/img/refresh.png"
              />
              Sync to Pallet
            </base-button>
          </div>
        </div>
      </div>
      <div class="view-section-tables">
        <v-app id="inspire">
          <!-- Expanded Lane table -->
          <expanded-lane-table
            v-if="selectedField == sectionList.lane"
            :tableData="lanesList"
            :isTariff="false"
            @remove-item="removeItem"
          />

          <!-- Accessorial table -->
          <accessorial-fsc-table
            v-if="selectedField == sectionList.accessorial"
            :tableData="accessorialList"
            :isTariff="false"
            :isParentView="true"
            :sectionName="sectionList.accessorial"
            @remove-item="removeItem"
          />

          <!-- Fuel Surcharge table -->
          <accessorial-fsc-table
            v-if="selectedField == sectionList.fuelSurcharge"
            :tableData="fuelSurchargeList"
            :isTariff="false"
            :isParentView="true"
            :sectionName="sectionList.fuelSurcharge"
            @remove-item="removeItem"
          />

          <!-- Condition table -->
          <condition-table
            v-if="selectedField == sectionList.conditions"
            :tableData="conditionList"
            :isTariff="false"
            :isParentView="true"
            :sectionName="sectionList.conditions"
            @remove-item="removeItem"
          />

          <!-- Baserate table -->
          <base-rate-table
            v-if="selectedField == sectionList.classBaseRate"
            :tableData="baseRateList"
            :isTariff="false"
            :isParentView="true"
            :sectionName="sectionList.classBaseRate"
            @remove-item="removeItem"
          />

          <div
            v-if="selectedField == sectionList.customerOrCustomerGroup"
            class="customer-tab"
          >
            <div class="mt-3 font-weight-600">
              Customer Groups
            </div>
            <div class="c-g-t">
              <expanded-customer-group-table
                :tableData="customerGroupsList"
                :isTariff="false"
                :isParentView="true"
                :isPagination="true"
                :isAction="true"
                :sectionName="sectionList.customerOrCustomerGroup"
                @remove-item="removeItem"
              />
            </div>

            <div class="mt-3 font-weight-600">
              Customers
            </div>
            <customer-table
              :tableData="customerList"
              :groupDetail="null"
              :isTariff="false"
              :isPagination="true"
              :isAction="true"
              :sectionName="sectionList.customerOrCustomerGroup"
              @remove-item="removeItem"
            />
          </div>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from '@/components/SingleSelect';
import ExpandedLaneTable from '@/views/common/ExpandedLaneTable.vue';
import AccessorialFscTable from '@/views/common/AccessorialFscTable.vue';
import ConditionTable from '@/views/common/ConditionTable.vue';
import ExpandedCustomerGroupTable from '@/views/customers/ExpandedCustomerGroupTable.vue';
import CustomerTable from '@/views/common/CustomerTable.vue';
import BaseRateTable from "@/views/common/BaseRateTable.vue";
import AppHeader from '@/layout/AppHeader.vue';
import Fuse from 'fuse.js';
import {API, masterAPI} from '@/api/API';
import {
  accountModuleApi,
  dataType,
  filterCustomerObjectsById,
  getAllCustomerOrCustomerGroupApi,
  getMethodKeyByItemObject,
  pricingMethodType,
  sectionList,
  unitList,
} from '@/helpers/utility';
import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
  name: "light-table",
  components: {
    ChoicesSingle,
    ExpandedLaneTable,
    AccessorialFscTable,
    ConditionTable,
    ExpandedCustomerGroupTable,
    CustomerTable,
    AppHeader,
    BaseRateTable
  },
  data() {
    return {
      activeMenuName: "viewallsection",
      searchSection: "",
      sectionFieldList: [],
      selectedField: sectionList.lane,
      sectionList: sectionList,
      dataType: dataType,
      pricingMethodType: pricingMethodType,
      unitList: unitList,
      // Lane
      lanesList: [],
      lanesOldList: [],
      // Accessorial
      accessorialList: [],
      accessorialOldList: [],
      // Fuel Surcharge
      fuelSurchargeList: [],
      fuelSurchargeOldList: [],
      conditionList: [],
      conditionOldList: [],
      customerGroupsList: [],
      customerGroupsOldList: [],
      customerList: [],
      customerOldList: [],
      selectedTab: true,
      baseRateList: [],
      baseRateOldList: [],
      isSyncing: false,
    };
  },
  computed: {
    ...mapGetters(["userIsBroker", "user"])
  },
  methods: {
    /**
     * Retrieves all sections from the API and populates the sectionFieldList.
     */
    async getAllSection() {
      this.sectionFieldList = await accountModuleApi(
        API.API_ENDPOINT.availableSections
      );

      if (this.userIsBroker) {
        this.sectionFieldList = this.sectionFieldList?.filter(
          section => section.itemDetail?.key !== "customerOrCustomerGroup"
        );
      }
    },
    /**
     * Retrieves all lanes from the API and populates the lanesList and lanesOldList.
     */
    async getAllLanes() {
      this.lanesList = [];
      this.lanesOldList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.lanes,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listLanes = response.data;
        let lanePriceMethodList = await accountModuleApi(
          API.API_ENDPOINT.lanePricingMethods
        );

        for (let i = 0; i < listLanes.length; i++) {
          let lane = listLanes[i];
          let type = lane.pricingMethod.type;

          let pricingMethodItem = getMethodKeyByItemObject(
            lanePriceMethodList,
            type
          );

          let pricingMethodName = pricingMethodItem.name;

          let laneDetail = {
            name: lane.name,
            origin: lane.origin,
            destination: lane.destination,
            subCity: lane.subCity,
            pricingMethod: type,
            value:
              type == dataType.fixed.toLowerCase()
                ? lane.pricingMethod.value
                : lane.pricingMethod.value.sort((a, b) => a.unit - b.unit),
            createdAt: moment(lane.createdAt).format("MMM Do, YYYY"),
            id: lane.id,
            edit: "",
            duplicate: "",
            pricingMethodItem: pricingMethodItem,
            pricingMethodTypeName: pricingMethodName
          };
          this.lanesList.push(laneDetail);
          this.lanesOldList.push(laneDetail);
        }
      }
    },
    /**
     * Retrieves all accessorial items from the API and populates the accessorialList and accessorialOldList.
     */
    async getAllAccessorial() {
      this.accessorialList = [];
      this.accessorialOldList = [];
      let response = await masterAPI(
        API.API_ENDPOINT.accessorial + "/" + this.selectedField,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listAccessorial = response.data;
        for (let i = 0; i < listAccessorial.length; i++) {
          let accessorial = listAccessorial[i];

          let accessorialDetail = {
            name: accessorial.name,
            description: accessorial.description,
            dataTypeFields: accessorial.dataTypeFields,
            createdAt: moment(accessorial.createdAt).format("MMM Do, YYYY"),
            id: accessorial.id,
            edit: "",
            duplicate: ""
          };
          this.accessorialList.push(accessorialDetail);
          this.accessorialOldList.push(accessorialDetail);
        }
      }
    },
    /**
     * Retrieves all fuel surcharge items from the API and populates the fuelSurchargeList and fuelSurchargeOldList.
     */
    async getAllFuelSurcharge() {
      this.fuelSurchargeList = [];
      this.fuelSurchargeOldList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.fuelsurcharge + "/" + this.selectedField,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listFuelSurcharge = response.data;
        for (let i = 0; i < listFuelSurcharge.length; i++) {
          let fuelSurcharge = listFuelSurcharge[i];

          let fuelSurchargeDetail = {
            name: fuelSurcharge.name,
            description: fuelSurcharge.description,
            dataTypeFields: fuelSurcharge.dataTypeFields,
            createdAt: moment(fuelSurcharge.createdAt).format("MMM Do, YYYY"),
            id: fuelSurcharge.id,
            edit: "",
            duplicate: ""
          };
          this.fuelSurchargeList.push(fuelSurchargeDetail);
          this.fuelSurchargeOldList.push(fuelSurchargeDetail);
        }
      }
    },
    /**
     * Handles the selection of a section field.
     * @param {string} e - The selected section field.
     */
    selectField(e) {
      this.lanesList = [];
      this.lanesOldList = [];
      this.accessorialList = [];
      this.accessorialOldList = [];
      this.fuelSurchargeList = [];
      this.fuelSurchargeOldList = [];
      this.conditionList = [];
      this.conditionOldList = [];

      this.baseRateList = [];
      this.baseRateOldList = [];
    
      if (e == this.sectionList.lane) {
        this.getAllLanes();
      } else if (e == this.sectionList.accessorial) {
        this.getAllAccessorial();
      } else if (e == this.sectionList.fuelSurcharge) {
        this.getAllFuelSurcharge();
      } else if (e == this.sectionList.conditions) {
        this.getAllCondition();
      } else if (e == this.sectionList.customerOrCustomerGroup) {
        this.getAllCustomersOrGroup();
      } else if (e == this.sectionList.classBaseRate) {
        this.listClassDiscounts();
      }
    },
    /**
     * Performs a search on the selected section field.
     * @param {string} searchValue - The search value.
     */
    searchAllSection(searchValue) {
      if (searchValue.length < 3) {
        return;
      }
      if (this.selectedField == this.sectionList.lane) {
        this.searchLanes(searchValue);
      } else if (this.selectedField == this.sectionList.accessorial) {
        this.searchAccessorial(searchValue);
      } else if (this.selectedField == this.sectionList.fuelSurcharge) {
        this.searchFuelSurcharge(searchValue);
      } else if (this.selectedField == this.sectionList.conditions) {
        this.searchCondition(searchValue);
      } else if (this.selectedField == this.sectionList.customerOrCustomerGroup) {
        this.searchCustomerGroup(searchValue);
      } else if (this.selectedField == this.sectionList.classBaseRate) {
        this.searchBaseRate(searchValue);
      }
    },
    /**
     * Performs a search on the lanes list based on the search value.
     * @param {string} searchValue - The search value.
     */
    searchLanes(searchValue) {
      const options = {
        keys: [
          "origin",
          "destination",
          "subCity.origin.city",
          "subCity.destination.city"
        ],
        includeScore: true
      };

      const fuse = new Fuse(this.lanesOldList, options);
      const result = fuse.search(searchValue);
      this.lanesList = result.map(res => res.item);
      // let searchItem = this.lanesOldList
      //   .map(filterItem => {
      //     if (
      //       filterItem.origin.toLowerCase().indexOf(searchValue.toLowerCase()) >
      //         -1 ||
      //       filterItem.destination
      //         .toLowerCase()
      //         .indexOf(searchValue.toLowerCase()) > -1
      //     ) {
      //       return filterItem;
      //     }
      //   })
      //   .filter(function(element) {
      //     return element !== undefined;
      //   });
      // this.lanesList = searchItem;
    },
    /**
     * Performs a search on the accessorial list based on the search value.
     * @param {string} searchValue - The search value.
     */
    searchAccessorial(searchValue) {
      let searchItem = this.accessorialOldList
        .map(filterItem => {
          if (
            filterItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          ) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      this.accessorialList = searchItem;
    },
    /**
     * Performs a search on the fuel surcharge list based on the search value.
     * @param {string} searchValue - The search value.
     */
    searchFuelSurcharge(searchValue) {
      let searchItem = this.fuelSurchargeOldList
        .map(filterItem => {
          if (
            filterItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          ) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      this.fuelSurchargeList = searchItem;
    },
    /**
     * Performs a search on the baserate list based on the search value.
     * @param {string} searchValue - The search value.
     */
    searchBaseRate(searchValue) {
      let searchItem = this.baseRateOldList
        .map(filterItem => {
          if (
            filterItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          ) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      this.baseRateList = searchItem;
    },
    /**
     * Retrieves the pricing method symbol based on the pricing method type.
     * @param {string} priceMethod - The pricing method type.
     * @returns {string} - The pricing method symbol.
     */
    pricingMethodSymbol(priceMethod) {
      let placeholder = "";
      if (priceMethod == this.pricingMethodType.cwt) {
        placeholder = "CWT";
      } else if (priceMethod == this.pricingMethodType.linearFoot) {
        placeholder = "LFT";
      } else if (priceMethod == this.pricingMethodType.weightRange) {
        placeholder = "lbs";
      } else if (priceMethod == this.pricingMethodType.perPallet) {
        let unitLength = this.unitPriceList.length;
        placeholder = unitLength <= 1 ? "pallet" : "pallets";
      }
      return placeholder;
    },
    /**
     * Removes an item from a specified section and triggers a corresponding data retrieval function.
     *
     * @param {string} section - The section from which to remove the item (e.g., 'lane', 'accessorial', 'fuelSurcharge').
     */
    removeItem(section) {
      if (section == sectionList.lane) {
        this.getAllLanes();
      } else if (section == sectionList.accessorial) {
        this.getAllAccessorial();
      } else if (section == sectionList.fuelSurcharge) {
        this.getAllFuelSurcharge();
      } else if (section == sectionList.conditions) {
        this.getAllCondition();
      } else if (section == sectionList.customerOrCustomerGroup) {
        this.getAllCustomersOrGroup();
      } else if (section == this.sectionList.classBaseRate) {
        this.listClassDiscounts();
      }
    },
    /**
     * Retrieves all condition from the API and populates the conditionList and conditionOldList.
     */
    async getAllCondition() {
      this.conditionList = [];
      this.conditionOldList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.condition,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.conditionList = response.data;
        this.conditionOldList = response.data;
      }
    },
    /**
     * Performs a search on the condition list based on the search value.
     * @param {string} searchValue - The search value.
     */
    searchCondition(searchValue) {
      let searchItem = this.conditionOldList
        .map(filterItem => {
          if (
            filterItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          ) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      this.conditionList = searchItem;
    },
    /**
     * Retrieves all customers and customer groups, and organizes customer groups with associated customers.
     */
    async getAllCustomersOrGroup() {
      this.customerGroupsList = [];
      this.customerGroupsOldList = [];
      let customers = await getAllCustomerOrCustomerGroupApi(false);
      let customerGroupList = await getAllCustomerOrCustomerGroupApi(true);

      customerGroupList.map(async groups => {
        const filteredCustomers = filterCustomerObjectsById(
          customers,
          groups.customers
        );
        groups.children = filteredCustomers;
        this.customerGroupsList.push(groups);
        this.customerGroupsOldList.push(groups);
      });

      this.customerList = customers;
      this.customerOldList = customers;
    },
    /**
     * Searches for customer groups based on the provided search value and updates the displayed list.
     * @param {string} searchValue - The value to search for in customer group names.
     */
    searchCustomerGroup(searchValue) {
      let searchCustomerGroupItem = this.customerGroupsOldList.filter(
        filterItem =>
          filterItem.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      this.customerGroupsList = searchCustomerGroupItem;

      let searchCustomerItem = this.customerOldList.filter(filterItem =>
        filterItem.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      this.customerList = searchCustomerItem;
    },
    async listClassDiscounts() {
      this.baseRateList = [];
      this.baseRateOldList = [];
      let response = await masterAPI(
        API.API_ENDPOINT.classDiscount,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.baseRateList = response.data;
        this.baseRateOldList = response.data;
      }
    },
    /**
     * Handles the tab selection and triggers the appropriate API call.
     * @param {string} activeTab - The selected tab.
     */
    handleTab(activeTab) {
      if (activeTab != this.selectedTab) {
        this.selectedTab = activeTab;
      }
    },

    async syncWithPallet() {
      this.isSyncing = true;

      let bodyPayload = JSON.stringify({
        apiKey: this.user.apiKey,
        broker: true
      });
      // await new Promise(resolve => setTimeout(resolve, 2000));
      // this.$toast.success("Sync successful");

      let response = await masterAPI(
        API.API_ENDPOINT.syncCarrierWithPallet,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      this.isSyncing = false;

      if (response.status === 200) {
        this.$toast.success("Sync successful");
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ""));
      }
    }
  },
  async beforeMount() {
    if (this.$route.params.preselected) {
      this.selectedField = this.$route.params.preselected;
    } else {
      this.selectedField = this.sectionList.lane;
    }
    this.selectField(this.selectedField);
  },
  async created() {
    this.getAllSection();
  }
};
</script>

<style></style>
