<template>
  <div>
    <div class="create-section-text mb-2">
      Choose a currency type
    </div>

    <div class="field-dropdown-main mb-4 price-method-dropdwon">
      <choices-single 
        :key="currencyKey"  
        v-model="currentCurrency" 
        :options="currencyList"
        class="currency-dropdown"
      >
        <option value="0">Select Currency</option>
      </choices-single>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from "@/components/SingleSelect";
import { currencyList } from "@/helpers/utility";
import { mapGetters } from "vuex";

export default {
  components: {
    ChoicesSingle,
  },
  props: {
    currency: {
      type: String,
      default: "USD",
    },
  },
  data() {
    return {
      currencyList: currencyList,              
      localCurrency: this.currency,  // Local currency model
      currencyKey: Date.now(),  // Initialize with a unique timestamp to ensure reactivity
    };
  },
  computed: {
    ...mapGetters(['currencyType']),  // Get account currency from Vuex

    // v-model for the dropdown
    currentCurrency: {
      get() {
        // Return localCurrency if not null, otherwise fallback to account currency
        return this.localCurrency !== null ? this.localCurrency : this.currencyType;
      },
      set(value) {
        this.localCurrency = value;  // Update local currency

        // Emit the updated value to the parent (this works with .sync)
        this.$emit('update:currency', value);

        this.updateDropdownKey();  // Update the key to force re-render
      }
    }
  },
  watch: {
    // Watch for changes in the prop `currency` and update dropdown
    currency(newVal) {
      // Ensure the localCurrency is updated when the parent changes
      this.localCurrency = newVal;
      this.updateDropdownKey();  // Trigger re-render
    },
    // Watch for changes in account currency and update currency
    currencyType(newVal) {
      this.localCurrency = newVal;  // Update local currency to match account currency
      this.$emit('update:currency', newVal);  // Emit the new value to the parent
      this.updateDropdownKey();  // Trigger re-render if necessary
    }
  },
  methods: {
    updateDropdownKey() {
      // Change currencyKey to a new timestamp to trigger re-render
      this.currencyKey = Date.now();  // Unique identifier to force re-render
    }
  },
  mounted() {
    // Emit the initial value when the component is first rendered
    this.$emit('update:currency', this.currentCurrency);
  },
};
</script>

<style></style>