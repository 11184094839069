<template>
    <div>
        <div v-if="selectedBaseUnit">
            <div class="create-section-text mb-2">
                Choose a Class
            </div>
           
            <div class="field-dropdown-main mb-4 price-method-dropdwon">
                <choices-single 
                    v-model="localSelectedClassUnits" 
                    :options="classUnitsListResult"
                    @input="event => handleSelectClassUnits(event)" 
                    :value="localSelectedClassUnits" 
                    id="selectedClassUnitsList"
                >
                    <option value="0">Select class</option>
                </choices-single>

                <span v-if="classRateInvalid" class="validate-error">
                    The Class is required
                </span>
            </div>
        </div>

        <div v-if="localSelectedClassUnits">
            <!-- View Class Button -->
            <div>
                <base-button
                    type="submit"
                    class="btn btn-default px-2 mt-2"
                    v-on:click="showClassBreaks"
                >
                    <img src="../../assets/img/plus-active.svg" class=" pr-2" />
                    <span class="pr-3">
                        View Class Breaks and Apply Discounts 
                    </span>
                </base-button>
            </div>
            
            <!-- Input fields appears here after button click -->
            <div v-if="localIsViewClassBreaks" class="row mb-2 mt-4">
                <div class="col-md-3">
                    <base-input 
                        type="number" 
                        label="Per hundred lbs" 
                        step="any" 
                        class="mb-0" 
                        placeholder="500" 
                        :disabled="true"
                        v-model="perHundredLbs"
                    >
                    </base-input>
                </div>
            
                <div class="form-group has-label col-md-4">
                    <label>Price</label>
                    <div>
                        <span class="data-type-input currency-symbol-custom" :data-symbol="currencySymbol" :class="{ 'currency-symbol-dollarSign': currencySymbol === '$' }"> 
                            <input 
                                type="number" 
                                step="any" 
                                class="mb-0 form-control" 
                                placeholder="28.97"
                                v-model="price"
                            />
                        </span>
                    </div>
                </div>

                <div class="col-md-3">
                    <base-input 
                        type="number" 
                        label="Discount" 
                        step="any" 
                        class="mb-0" 
                        placeholder="%"
                        v-model="discount"
                    >
                    </base-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { classDataBaseLane } from "@/helpers/utility";
import ChoicesSingle from "@/components/SingleSelect";

export default {
    bodyClass: "landing",
    components: {
        ChoicesSingle
    },
    props: {
        selectedBaseUnit: {
            type: String
        },
        selectedClassUnits: {
            type: String
        },
        isViewClassBreaks: {
            type: Boolean
        },
        currencySymbol: {
            type: String
        },
        classRateInvalid: {
            type: Boolean
        },
    },
    data() {
        return {
            localIsViewClassBreaks: this.isViewClassBreaks, // Create local data based on prop
            localSelectedClassUnits: this.selectedClassUnits,
            perHundredLbs: "",
            discount: "",
            price: ""
        };
    },
    watch: {
        // Watch the prop and update local data if the prop changes
        isViewClassBreaks(newVal) {
            this.localIsViewClassBreaks = newVal;
        },
        selectedClassUnits(newVal) {
            this.localSelectedClassUnits = newVal;
        },
    },
    methods: {
        /**
         * Show class breaks by setting a local flag and emitting an event to the parent component
         */
        showClassBreaks() {
            this.localIsViewClassBreaks = true; 
            this.$emit('update:isViewClassBreaks', true); 
        },
        /**
         * Emits the selected class units to the parent and hides class breaks view
         */
        handleSelectClassUnits() {
            this.$emit('select-class-units', this.localSelectedClassUnits)
            this.$emit('update:isViewClassBreaks', false); 
        },
    },
    computed: {
        classUnitsListResult() {
            return classDataBaseLane.map(option => ({
                id: option.key,
                text: option.name
            }));
        },
    }
}
</script>